<style lang="scss" scoped>
.create-portal {
  margin-left: 3px;
}
.btn {
  font-size: 0.8rem;
}
.portal-list-table {
  font-size: 0.8rem;
  /deep/caption {
    font-size: 1rem;
    font-weight: bold;
  }
}

.portal-toolbar-buttons {
  float: right;
  display: inline;
}

span.published-status {
  font-size:0.8em;
  .published{
    font-color:green;
  }
  .draft{
    font-color:grey;
  }
}
</style>
<template>
  <b-container fluid>
    <b-overlay
      :show="isBusyBoolean"
      rounded
      opacity="0.9"
      class="d-inline-block"
    >
      <template v-slot:overlay>{{ isBusyString }}</template>
      <template v-slot:empty="scope">
        {{ scope.emptyHtml }}
      </template>
      <b-table
        ref="portalListTable"
        class="portal-list-table"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        caption-top
        show-empty
        :empty-html="emptyHtml"
        selectable
        select-mode="single"
        v-on:row-clicked="rowClicked"
        :busy.sync="isBusyBoolean"
        small
      >
        <template v-slot:table-caption
          >My Portals:
          <div class="portal-toolbar-buttons">
            <b-button size="sm" :disabled="isBusyBoolean"
              >All changes saved</b-button
            >
            <b-button
              class="create-portal"
              variant="outline-info"
              size="sm"
              @click="createPortal"
              :disabled="isBusyBoolean"
            >
              Create Portal
              <b-icon icon="plus-circle-fill" font-scale="1"></b-icon>
            </b-button>
          </div>
        </template>
        <template v-slot:cell(published)="row">
          <b-form-checkbox
            :checked="row.item.published == 1"
            switch
            size="sm"
            @input="updatePublishedStatus(row.item, row.index, $event)"
            ><template v-if="row.item.published == 1"><span class="published-status published">Published</span>
              </template
            ><template v-else
              ><span class="published-status draft">Draft</span></template
            ></b-form-checkbox
          >
        </template>
        <template v-slot:cell(last_updated)="row"
          ><span :title="row.value"
            ><timeago
              :datetime="`${row.value}`"
              :auto-update="60"
            ></timeago></span
        ></template>
        <template v-slot:cell(actions)="row">
          <b-button-toolbar key-nav aria-label="Toolbar with button groups">
            <b-button-group size="sm">
              <b-button
                @click="info(row.item, row.index, $event.target)"
                title="info"
                ><b-icon icon="info-circle" font-scale="1"></b-icon>
              </b-button>
              <b-button
                variant="light"
                title="Show Details"
                @click="row.toggleDetails"
              >
                {{ row.detailsShowing ? "Hide" : "Show" }} Details
              </b-button>
              <b-button
                variant="danger"
                title="Delete"
                @click="deletePortal(row.item, row.index, $event.target)"
                ><b-icon icon="trash" font-scale="1"></b-icon
              ></b-button>
            </b-button-group>
          </b-button-toolbar>
        </template>

        <template v-slot:row-details="row">
          <p v-if="row.item.published == 1">Published address: <router-link target="_blank" :to="{ name: 'publishedLanding', params: { portalId: row.item.uuid }}">View Published Portal</router-link></p>
          <p v-else>Portal still in draft mode</p>
        </template>
      </b-table></b-overlay
    >

    <!-- Info modal -->
    <b-modal
      :id="infoModal.id"
      :title="infoModal.title"
      ok-only
      @hide="resetInfoModal"
    >
      <pre>{{ infoModal.content }}</pre>
    </b-modal>
  </b-container>
</template>
<script>
export default {
  components: {},
  name: "MyPortalsList",
  data: () => {
    return {
      fields: [
        { key: "portal_title", sortable: true },
        {
          key: "published",
          sortable: true,
          formatter: function (value) {
            return value;
          },
        },
        {
          key: "last_updated",
          sortable: true,
          formatter: function (value) {
            return new Date(value + " UTC");
          },
        },
        { key: "actions", label: "Actions" },
      ],
      sortBy: "portal_title",
      sortDesc: false,
      emptyHtml: "Fetching saved portals",
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
    };
  },
  computed: {
    selectedPortalIndex() {
      return this.$store.state.manager.selectedPortalIndex;
    },
    isBusyString: {
      get() {
        return this.$store.state.manager.isBusyString;
      },
      set(value) {
        this.$store.commit("updateBusy", value);
      },
    },
    isBusyBoolean: {
      get() {
        return this.$store.state.manager.isBusyBoolean;
      },
      set(value) {
        this.$store.commit("updateBusy", value);
      },
    },
    items() {
      return this.$store.state.manager.savedPortalConfigs;
    },
    userName() {
      return this.$store.state.manager.userName;
    },
    savedPortalConfigs() {
      return this.$store.state.manager.savedPortalConfigs;
    },
    portal() {
      return this.$store.state.portal;
    },
  },
  mounted() {
    this.fetchSavedPortals();
  },
  methods: {
    createPortal: function () {
      // Post config and receive a new UUID
      var that = this;
      var initialPortalState = that.$store.state.initialPortalState;
      var saveData = new FormData();
      saveData.append("config", JSON.stringify(initialPortalState));
      that.$store.commit("savingPortalConfig", true);
      const options = {
        method: "POST",
        data: saveData,
        url:
          process.env.VUE_APP_MANAGER_SERVER_URL +
          "/user/spaces/create_portal_config",
        withCredentials: true,
      };
      this.$http(options)
        .then((response) => {
          console.log("created new portal", response.data);
          var newPortalConfig = response.data.portal;
          that.$store.commit("insertNewPortalConfig", newPortalConfig);
          that.$store.commit("savingPortalConfig", false);
          that.$refs.portalListTable.selectRow(that.selectedPortalIndex);
          that.$store.commit("updateBusy", false);
        })
        .catch(function (error) {
          that.$store.commit("updateBusy", false);
          console.log(error);
        });
    },
    updatePublishedStatus: function (item, index, event) {
      console.log("Update Publish status", item, index, event);
      var that = this;

      that.isBusyString =
        (event ? "Publishing" : "Unpublishing") + " portal...";
        var publishedInt = (event ? 1 : 0);
      const options = {
        method: "POST",
        data: {
          uuid: item.uuid,
          published: publishedInt,
        },
        url:
          process.env.VUE_APP_MANAGER_SERVER_URL +
          "/user/spaces/set_portal_published_status",
        withCredentials: true,
      };
      this.$http(options)
        .then((response) => {
          console.log(response.data);
          const payload = {
            idx: that.$store.getters.getUuidIdx(response.data.uuid),
            published: publishedInt,
            last_updated: response.data.last_updated,
          };
          
          that.$store.commit("updateManagerSavedPortalPublishedStatusForUuid", payload);
          that.isBusyBoolean = false;
        })
        .catch(function (error) {
          that.isBusyBoolean = false;
          console.log(error);
        });
    },
    rowClicked: function (item, index, event) {
      const idx = this.$store.getters.getUuidIdx(item.uuid);
      this.$store.commit("loadPortalConfig", idx);
      console.log("Row Clicked:", item, index, event);
    },
    fetchSavedPortals: function () {
      var that = this;
      that.isBusyString = "Fetching Saved Portals...";
      console.log("Fetching Saved Portals");
      const options = {
        method: "GET",
        url:
          process.env.VUE_APP_MANAGER_SERVER_URL +
          "/user/spaces/get_user_portals",
        withCredentials: true,
      };
      const promise = this.$http(options);
      return promise
        .then((response) => {
          console.log(response.data);
          that.$store.commit("updateManagerSavedPortalConfigs", response.data);
          that.emptyHtml =
            "<div>You have no previously saved portals... <br>Click on 'Create portal' above.</div>";
          that.isBusyBoolean = false;
        })
        .catch(function (error) {
          that.isBusyBoolean = false;
          console.log(error);
        });
    },
    deletePortal(item, index, button) {
      console.log("deleting", item, index, button);
      var that = this;
      this.isBusyString = "Deleting Portal...";
      this.$bvModal
        .msgBoxConfirm(
          that.$createElement("p", {
            domProps: {
              innerHTML: `Please confirm that you want to delete <b>${item.portal_title}</b>.`,
            },
          }),
          {
            title: "Please Confirm",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "Delete",
            cancelTitle: "Cancel",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            // Now make an axios request to delete and also remove from store.
            const options = {
              method: "POST",
              url:
                process.env.VUE_APP_MANAGER_SERVER_URL +
                "/user/spaces/delete_portal_config",
              data: { uuid: item.uuid },
              withCredentials: true,
            };
            const promise = this.$http(options);
            return promise
              .then((response) => {
                console.log(response.data);
                if (response.data.success) {
                  console.log("Portal successfully deleted");
                  that.$store.commit("removePortalConfigWithUUID", item.uuid);
                } else {
                  console.error("Delete failed");
                }
                that.isBusyBoolean = false;
              })
              .catch(function (error) {
                that.isBusyBoolean = false;
                console.error(error);
              });
          }
          this.isBusyBoolean = false;
        })
        .catch((err) => {
          // An error occurred
          console.log(err);
        });
    },
    info(item, index, button) {
      this.infoModal.title = `${item.portal_title}`;
      console.log(JSON.parse(item.config));
      this.infoModal.content += `${JSON.parse(item.config).linkTree.length} Portal Topics \r\n`;
      this.infoModal.content += `${item.published == 1 ? 'Published' : 'Unpublished'} \r\n`;
      this.infoModal.content += `Last updated ${item.last_updated}`;
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
  },
};
</script>
