<template>
  <div>
    <b-button
      variant="secondary"
      v-on:click.stop.prevent="addHeading"
    >Add Heading</b-button>
  </div>
</template>
<script>
export default {
  name: "PageElementsForm",
  methods:{
      addHeading: function(){
          this.$store.commit('addLinkTreeHeading');
      }
  }
};
</script>
