<template>
  <b-container id="app" v-cloak fluid>
    <!-- route outlet -->
    <!-- component matched by the route will render here -->
    <router-view></router-view>
  </b-container>
</template>
<script>
import Vue from "vue";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import VueTimeago from 'vue-timeago';

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

Vue.use(VueTimeago, {
  name: 'Timeago', // Component name, `Timeago` by default
  locale: 'en'
});

// global variable for customCSS Container

var head = document.head || document.getElementsByTagName("head")[0],
  style = document.createElement("style");

style.id = "brilliant-spaces-custom-css"; // Add unique ID
var bsCustomCSS = head.appendChild(style);
style.type = "text/css";

Vue.prototype.$customCssContainer = bsCustomCSS;

export default {
  name: "App",
};
</script>
<style>
#app {
  font-family: Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
}

.container-fluid {
  padding-right: 0px;
  padding-left: 0px;
}

img.brilliant-spaces-logo {
  width: 100%;
}

[v-cloak]>* {
  display: none;
}

[v-cloak]::before {
  content: " ";
  display: block;
  position: absolute;
  width: 80px;
  height: 80px;
  background-image: url(./assets/images/loader_circle.svg);
  background-size: cover;
  left: 50%;
  top: 50%;
}

.accordion-header span {
  float: right;
}

.accordion-header span.closed {
  display: none;
}

.accordion-header .collapsed span.open {
  display: none;
}

.accordion-header .collapsed span.closed {
  display: inline-block;
}

.form-inline {
  justify-content: space-between;
}

.form-inline input.form-control {
  width: 2rem;
}

.sidebar {
  z-index: 2;
  /* for the animation */
  transition: margin 0.3s ease;
}

.preview-pane {
  z-index: 1;
  /* for the animation */
  transition: width 0.3s ease;
}

.sidebar-collapsed {
  /* hide it for small displays*/
  display: none;
}

@media (min-width: 992px) {
  .sidebar-collapsed {
    display: block;
    /* same width as sidebar */
    margin-left: -25%;
  }
}

.row-main {
  /* necessary to hide collapsed sidebar */
  overflow-x: hidden;
}

#app .sidebar {
  max-height: 100vh;
  overflow: hidden;
  display: inline-block;
  overflow-y: scroll;
  max-width: 25%;
}

@media (max-width: 1600px) {
  #app .sidebar {
    max-width: 400px;
  }
}
</style>