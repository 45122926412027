<style lang="scss">
.drag-handle,
.edit-link,
.delete-link {
  margin-right: 0em;
  visibility: hidden;
  opacity: 0;
  transition-property: margin-left, margin-right, width, visibility, opacity;
  transition-duration: 0.1s;
  transition-timing-function: linear;
}

li:hover,
li .active {

  .drag-handle,
  .edit-link,
  .delete-link {
    visibility: visible;
    opacity: 1;
  }

  .drag-handle {
    margin-right: 0.3em;
  }

  .edit-link,
  .delete-link {
    margin: 0 0.7em;
  }
}

.sub-category li {
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0 0 0.1em 0;
}

li.subheading-title {
  height: 1.5em;
}
</style>

<template>
  <ul :class="[columnClass, 'list-unstyled']">
    <template v-if="!published">
      <li class="subheading-title">
        <b-icon-arrows-move class="drag-handle"></b-icon-arrows-move>
        <span class="sub-heading1" :title="subheading.title" contenteditable="true" @keydown="handleKeyDown"
          @blur="handleEndEdit">{{ subheading.title }}</span>

        <b-button-group>
          <b-button class="action-button" @click="addSubHeadingLink" variant="primary" size="sm">
            <b-icon-plus-circle-fill size="sm"></b-icon-plus-circle-fill>
          </b-button>
          <b-button @click="deleteSubTopic" variant="danger" size="sm" class="action-button">
            <b-icon-trash-fill size="sm"></b-icon-trash-fill>
          </b-button>
        </b-button-group>
      </li>
      <draggable v-model="linksAlias" v-bind="dragOptions" tag="div" @end="myEnd" @start="myStart" :clone="myClone"
        @change="myChange" @sort="mySort" :move="myMove">
        <li v-for="(link, index) in links" v-bind:key="index">
          <editable-list-item v-bind:link="link" v-bind:links="links"></editable-list-item>
        </li>
      </draggable>
    </template>
    <template v-else>
      <li class="subheading-title">
        <span class="sub-heading1" :title="subheading.title">{{
          subheading.title
        }}</span>
      </li>
      <div>
        <li v-for="(link, index) in links" v-bind:key="index">
          <editable-list-item v-bind:link="link" v-bind:links="links" v-bind:published="published"></editable-list-item>
        </li>
      </div>
    </template>
  </ul>
</template>
<style lang="scss">
span.delete-title {
  font-weight: bold;
}
</style>
<script>
import { mapState } from "vuex";
import draggable from "vuedraggable";
import EditableListItem from "./EditableListItem.vue";
export default {
  components: {
    draggable,
    EditableListItem,
  },
  props: {
    published: {
      default: false,
      type: Boolean,
    },
    subheading: {},
    links: {},
    mainIndex: {},
    subIndex: {},
  },
  methods: {
    addSubHeadingLink: function () {
      console.log("adding subheading link");
      var that = this;
      let payload = {
        message: "Adding Sub-Topic Link",
        mainIndex: that.mainIndex,
        subIndex: that.subIndex,
      };
      that.$store.commit("addSubTopicHeadingLink", payload);
    },
    deleteSubTopic: function () {
      var that = this;
      const h = this.$createElement;
      // Using HTML string
      const titleVNode = h("div", {
        domProps: {
          innerHTML:
            "Please confirm that you want to delete this heading <span class='delete-title'>" +
            that.subheading.title +
            "</span>",
        },
      });
      this.$bvModal
        .msgBoxConfirm([titleVNode], {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            let payload = {
              message: "Deleting Sub-Topic",
              mainIndex: that.mainIndex,
              subIndex: that.subIndex,
            };
            that.$store.commit("deleteSubTopicHeading", payload);
          }
        })
        .catch((err) => {
          console.log(err);
          // An error occurred
        });
    },
    myMove: function (evt, origEvt) {
      console.log("myMove", evt, origEvt);
    },
    mySort: function (evt) {
      console.log("sort", evt);
    },
    myChange: function (evt) {
      console.log("Sub Change", evt);
    },
    myStart: function (evt) {
      console.log("myStart", evt);
    },
    myEnd: function (evt) {
      console.log("myEnd", evt);
    },
    myClone: function (evt) {
      console.log("myClone", evt);
      return evt;
    },
    handleEndEdit: function (evt) {
      // Scroll all way to left in case of overflow
      evt.target.scrollLeft = 0;
      let payload = {
        message: "Updating Sub-Topic on drag/drop reorder with index",
        mainIndex: this.mainIndex,
        subIndex: this.subIndex,
        value: evt.target.textContent,
      };
      this.$store.commit("updateSubTopicHeading", payload);
    },
    handleKeyDown: function (evt) {
      if (evt.keyCode == 13) {
        evt.preventDefault();
        evt.target.blur();
      }
    },
  },
  computed: {
    dragOptions() {
      return {
        animation: 150,
        group: "link-items",
        disabled: false,
        ghostClass: "ghost",
      };
    },
    linksAlias: {
      get() {
        return this.links;
      },
      set(value) {
        console.log("setting value", value);
        console.log("setting this ", this);
        console.log("updating main-link-topic index", this.mainIndex);
        let payload = {
          message: "Updating Sub-Topic on drag/drop reorder with index",
          mainIndex: this.mainIndex,
          subIndex: this.subIndex,
          value: value,
        };
        this.$store.commit("updateLinkTreeSubTopic", payload);
      },
    },
    ...mapState({
      portal: state => state.portal, // Access the entire portal object
    }),
    columnClass() {
      if (this.portal.columnSpacing === 'fixed') {
        return 'col-sm-2';
      } else {
        return 'col'; // Default to 'col' for 'fluid' or any other value
      }
    },
  },
};
</script>
