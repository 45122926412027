let linkTreeJSON =
    [
        {
            id: 1,
            title: 'topic 1',
            order: 1,
            subheadings: [
                {
                    id: 1,
                    title: 'SubHeading 1',
                    order: 1,
                    links: [
                        {
                            id: 1,
                            title: 'Link Title 1',
                            url: 'http://www.google.com',
                            active: 1,
                            order: 1
                        },
                        {
                            id: 2,
                            title: 'Test Link Title 2',
                            url: 'http://www.bbc.co.uk',
                            active: 1,
                            order: 2
                        },
                        {
                            id: 3,
                            title: 'Another Title 3',
                            url: 'http://www.nytimes.com',
                            active: 1,
                            order: 3
                        }
                    ]
                }/*,
                {
                    id: 2,
                    title: 'SubHeading 2',
                    order: 1,
                    links: [
                        {
                            id: 1,
                            title: 'Link Title 1',
                            url: 'http://www.google.com',
                            active: 1,
                            order: 1
                        },
                        {
                            id: 2,
                            title: 'Test Link Title 2',
                            url: 'http://www.google.com',
                            active: 1,
                            order: 2
                        },
                        {
                            id: 3,
                            title: 'Another Title 3',
                            url: 'http://www.google.com',
                            active: 1,
                            order: 3
                        }
                    ]
                }*/
            ]
        }/*, {
            id: 2,
            title: 'students',
            order: 2,
            subheadings: [
                {
                    id: 1,
                    title: 'SubHeading 1',
                    order: 1,
                    links: [
                        {
                            id: 1,
                            title: 'Link Title 1',
                            url: 'http://www.google.com',
                            active: 1,
                            order: 1
                        },
                        {
                            id: 2,
                            title: 'Test Link Title 2',
                            url: 'http://www.google.com',
                            active: 1,
                            order: 2
                        },
                        {
                            id: 3,
                            title: 'Another Title 3',
                            url: 'http://www.google.com',
                            active: 1,
                            order: 3
                        }
                    ]
                }
            ]
        }, {
            id: 3,
            title: 'faculty',
            order: 3,
            subheadings: [
                {
                    id: 1,
                    title: 'SubHeading 1',
                    order: 1,
                    links: [
                        {
                            id: 1,
                            title: 'Link Title 1',
                            url: 'http://www.google.com',
                            active: 1,
                            order: 1
                        },
                        {
                            id: 2,
                            title: 'Test Link Title 2',
                            url: 'http://www.google.com',
                            active: 1,
                            order: 2
                        },
                        {
                            id: 3,
                            title: 'Another Title 3',
                            url: 'http://www.google.com',
                            active: 1,
                            order: 3
                        }
                    ]
                }
            ]
        }, {
            id: 4,
            title: 'staff & admin',
            order: 4,
            subheadings: [
                {
                    id: 1,
                    title: 'SubHeading 1',
                    order: 1,
                    links: [
                        {
                            id: 1,
                            title: 'Link Title 1',
                            url: 'http://www.google.com',
                            active: 1,
                            order: 1
                        },
                        {
                            id: 2,
                            title: 'Test Link Title 2',
                            url: 'http://www.google.com',
                            active: 1,
                            order: 2
                        },
                        {
                            id: 3,
                            title: 'Another Title 3',
                            url: 'http://www.google.com',
                            active: 1,
                            order: 3
                        }
                    ]
                }
            ]
        }, {
            id: 5,
            title: 'research',
            order: 5,
            subheadings: [
                {
                    id: 1,
                    title: 'SubHeading 1',
                    order: 1,
                    links: [
                        {
                            id: 1,
                            title: 'Link Title 1',
                            url: 'http://www.google.com',
                            active: 1,
                            order: 1
                        },
                        {
                            id: 2,
                            title: 'Test Link Title 2',
                            url: 'http://www.google.com',
                            active: 1,
                            order: 2
                        },
                        {
                            id: 3,
                            title: 'Another Title 3',
                            url: 'http://www.google.com',
                            active: 1,
                            order: 3
                        }
                    ]
                }
            ]
        },*/
    ];

export default linkTreeJSON