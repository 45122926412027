function mergeWithDefaults(initialState, fetchedConfig) {
    // Use spread syntax and nullish coalescing for a clean merge
    return {
        ...initialState, // Start with the defaults
        ...fetchedConfig, // Override with fetched values (if they exist)
        //Apply the nullish coalesting on all properties
        ...Object.fromEntries(Object.entries(initialState).map(([key, value]) => [key, fetchedConfig?.[key] ?? value])),
    };
}

export default mergeWithDefaults;
