import Vue from 'vue'
import App from './App.vue'
import Axios from 'axios'

Vue.prototype.$http = Axios;

Vue.config.productionTip = false

import router from './router';
import store from './store';

// Custom event bubbler plugin https://stackoverflow.com/a/54940012/1672632
Vue.use((Vue) => {
    Vue.prototype.$bubble = function $bubble(eventName, ...args) {
      // Emit the event on all parent components
      let component = this;
      do {
        component.$emit(eventName, ...args);
        component = component.$parent;
      } while (component);
    };
  });

window.BrilliantSpacesVueInstance = new Vue({
    router,
    store,
    render: function (createElement) {
        return createElement(App);
    }
}).$mount('#app');