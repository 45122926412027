<style lang="scss" scoped>
iframe {
    border:none;
}
</style>

<template>
    <iframe v-bind:src="url" :width="width" :height="height" id="spacesIframe">
    </iframe>
</template>

<script>
export default {
    name: 'SpacesIframe',
    props: ['url', 'height', 'width']
}
</script>