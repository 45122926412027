<style lang="scss">
.navbar {
  /*position: fixed;*/
  width: 100%;
  z-index: 1;
  background-color: var(--nav-ribbon-background-color);
  padding: 0 0.5em 0 0;
  display: none;
}

.navbar-light .navbar-nav .dropdown:hover {
  background-color: var(--nav-ribbon-font-color);
  .nav-link:hover,
  .nav-link:focus {
    color: var(--nav-ribbon-background-color);
  }
}

.spaces-nav .navbar-nav a.main-heading {
  position: relative;
  height: 100%;
  color: var(--nav-ribbon-font-color);
  line-height: 34px;
}

.navbar-brand {
  padding-top: 0;
  padding-bottom: 0;
  img {
    height: 50px;
  }
}

.dropdown-menu {
  background: #fff;
  border: none;
  width: 100%;
  margin: 0px;
  padding: 0px;
  margin-top: 0px;
  border-radius: 0;
  box-shadow: 0px 6px 10px 0px #00000040;
}

.show-navbar-preview {
  nav.navbar,
  .tableau-dummy-worksheet {
    display: block;
  }
}

.dropdown:hover > .dropdown-menu {
  display: block;
}

nav.navbar .row {
  width: 100%;
  padding: 20px 30px;
}

.dropdown:hover,
.dropdown.show {
  position: static;
  a.main-heading::before {
    border-bottom: 10px solid #fff;
    border-left: 10px solid rgba(0, 0, 0, 0);
    border-right: 10px solid rgba(0, 0, 0, 0);
    bottom: 0px;
    content: "";
    display: block;
    height: 0;
    position: absolute;
    width: 0;
    left: calc(50% - 10px);
  }
}

.spaces-nav li.subheading-title {
  border-bottom: 1px solid #000;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
}

.tableau-dummy-worksheet {
  display: none;
  height: 100vh;
  width: 100%;
  position: absolute;
  padding-top: 50px;
  background-color: white;
  img {
    //height: calc(100vh - 50px);
    width: 100%;
    filter: opacity(0.5) grayscale(0.8);
  }
}

.subheading-link,
.subheading-title {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  a,
  span {
    -webkit-box-flex: 1;
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
<template>
  <div>
    <b-navbar class="spaces-nav">
      <b-navbar-nav>
        <b-navbar-brand href="#">
          <img ref="logo" :src="logoSrc" alt="Brilliant Spaces Logo Alt Text" />
        </b-navbar-brand>

        <!-- Navbar dropdowns -->

        <b-nav-item-dropdown
          v-for="(MainTopic, index) in linkTree"
          v-bind:text="MainTopic.title"
          v-bind:title="MainTopic.title"
          v-bind:key="index"
          v-bind:mainIndex="index"
          right
          id="menu1"
          toggle-class="main-heading"
        >
          <b-container fluid>
            <b-row>
              <b-col
                cols="2"
                v-for="(subheading, index) in MainTopic.subheadings"
                v-bind:key="index"
              >
                <li class="subheading-title" :title="subheading.title">
                  <span>{{ subheading.title }}</span>
                </li>
                <li
                  v-for="(link, index) in subheading.links"
                  v-bind:key="index"
                  class="subheading-link"
                >
                  <router-link
                    v-if="published"
                    :to="{
                      name: 'publishedView',
                      params: { portalLink: encodeURIComponent(link.url) },
                    }"
                    :title="link.title"
                    >{{ link.title }}</router-link
                  >
                </li>
              </b-col>
            </b-row>
          </b-container>
        </b-nav-item-dropdown>
        <b-navbar-nav class="ml-auto">
          <b-nav-item
            :to="{
              name: 'publishedLanding',
              params: { portalId: $route.params.portalId },
            }"
            title="Home"
            ><b-icon icon="house-fill"></b-icon> Home</b-nav-item
          >
        </b-navbar-nav>
      </b-navbar-nav>
    </b-navbar>
    <div class="tableau-dummy-worksheet">
      <img src="/assets/img/dummy_tableau_background.png" />
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "NavBarPreview",
  props: {
    published: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    ...mapState({
      logoSrc: (state) => state.portal.logoSrc,
      linkTree: (state) => state.portal.linkTree,
    }),
  },
};
</script>
