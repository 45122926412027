<template>
  <b-row class="row-main" no-gutters>
    <b-col class="preview-pane">
      <div v-if="errored">
        <p>
          We're sorry, we're not able to retrieve this information at the
          moment, please try back later
        </p>
      </div>
      <div v-else>
        <div v-if="loading">
          <div class="px-4 py-5 my-5 text-center">
            <img class="d-block mx-auto mb-4" src="../assets/images/loader_circle.svg" alt="" width="72" height="57">
            <h1 class="display-5 fw-bold">Loading Portal</h1>
            <div class="col-lg-6 mx-auto">
              <p class="lead mb-4">Just a few moments...</p>
            </div>
          </div>
        </div>
        <div v-else-if="$route.name == 'publishedLanding'">
          <preview-pane ref="previewPane" v-bind:published="true"></preview-pane>
        </div>
        <div v-else-if="$route.name == 'publishedView'">
          <div class="published-view">
            <NavBarPreview ref="nav-bar" v-bind:published="true"></NavBarPreview>
            <b-container fluid>
              <div ref="breadcrumb-container" class="breadcrumb-container">
                <b-breadcrumb>
                  <b-breadcrumb-item active>
                    {{ breadcrumb.heading }}</b-breadcrumb-item>
                  <b-breadcrumb-item active>
                    {{ breadcrumb.subheading }}</b-breadcrumb-item>
                  <b-breadcrumb-item active>
                    {{ breadcrumb.link_title }}</b-breadcrumb-item>
                </b-breadcrumb>
                <div class="actions">
                  <b-button-toolbar key-nav aria-label="Actions Toolbar">
                    <!--<b-button size="sm" class="mr-1" ref="favorite-button"><b-icon-heart></b-icon-heart> Add to
                      favorites</b-button>-->

                    <template v-if="linkItem.tableau_link">
                      <b-button v-if="resetView" size="sm" class="mr-1" ref="reset-button"
                        @click="resetViewFilters"><b-icon-arrow-clockwise></b-icon-arrow-clockwise> Reset
                        View</b-button>
                      <b-dropdown v-if="exportImage || exportPDF" size="sm" class="mr-1" text="Export">
                        <b-dropdown-item v-if="exportImage" @click="triggerExportImage">
                          <b-icon-file-earmark-image></b-icon-file-earmark-image>
                          Export Image
                        </b-dropdown-item>
                        <b-dropdown-item v-if="exportPDF" @click="triggerExportPDF">
                          <b-icon-file-earmark></b-icon-file-earmark>
                          Export PDF
                        </b-dropdown-item>
                      </b-dropdown>
                    </template>
                  </b-button-toolbar>
                </div>
              </div>
              <div class="col-md-12" id="content">
                <!--<div class="embed-container" ref="embed_target"></div>-->
                <template v-if="linkItem.tableau_link">
                  <SpacesTableau :url="tab_url" :height="tab_height" :width="tab_width" :filters="tab_filters"
                    ref="tableau" :apiUrl="tab_api_url"></SpacesTableau>
                </template>
                <template v-else>
                  <SpacesIframe :url="tab_url" :height="tab_height" :width="tab_width"></SpacesIframe>
                </template>
                <hr ref="footer-ruler" class="footer-ruler to-hide" style="" />
                <footer ref="footer" class="to-hide" style="">
                  <span class="footer-title">{{ footerText }}</span>
                  <span class="footer-detail">© Brilliant Spaces {{ new Date().getFullYear() }}</span>
                </footer>
              </div>
            </b-container>
          </div>
        </div>
      </div>
    </b-col>
  </b-row>
</template>
<style lang="scss">
.published-view .spaces-nav {
  display: inline-block;
}
</style>

<script>
import PreviewPane from "../components/PreviewPane.vue";
import NavBarPreview from "./NavBarPreview.vue";
import SpacesIframe from "../components/SpacesIframe.vue";
import SpacesTableau from "../components/SpacesTableau.vue";

export default {
  name: "PublishedView",
  components: {
    PreviewPane,
    NavBarPreview,
    SpacesIframe,
    SpacesTableau,
  },
  data() {
    return {
      publishedPortalConfig: null,
      loading: true,
      errored: false,
      breadcrumb: false,
      linkItem: false,
      tab_url: "",
      tab_filters: {
        /*"Storm Name": ["CELIA", "BLAS", "AGATHA"],
        Basin: "East Pacific",*/
      },
      tab_height: 0,
      tab_width: "100%",
      tab_api_url:
        "https://public.tableau.com/javascripts/api/tableau-2.5.0.min.js"
    };
  },
  computed: {
    footerText() {
      return this.$store.state.portal.footerText;
    },
    myPortalLink: function () {
      return decodeURIComponent(this.$route.params.portalLink);
    },
    exportImage() {
      return this.$store.state.portal.exportImage;
    },
    exportPDF() {
      return this.$store.state.portal.exportPDF;
    },
    resetView() {
      return this.$store.state.portal.resetView;
    },
  },
  /*updated: function () {
    var that = this;
    this.$nextTick(() => {
      //
      that.setContainerDimensions();
    });
  },*/
  methods: {
    setContainerDimensions() {
      var that = this;
      if (typeof that.$refs["nav-bar"] == "undefined") {
        // element not ready yet, re-load
        setTimeout(function () {
          that.setContainerDimensions();
        }, 100)
        return;
      }
      var windowHeight = window.innerHeight;
      var navBarHeight = that.getAbsoluteHeight(that.$refs["nav-bar"].$el);
      var breadcrumbHeight = that.getAbsoluteHeight(
        that.$refs["breadcrumb-container"]
      );
      var footerRulerHeight = that.getAbsoluteHeight(
        that.$refs["footer-ruler"]
      );
      var footerHeight = that.getAbsoluteHeight(that.$refs["footer"]);
      var myHeight = Math.floor(
        windowHeight -
        (navBarHeight + breadcrumbHeight + footerRulerHeight + footerHeight)
      );
      that.tab_height = myHeight;
    },
    getAbsoluteHeight(el) {
      // Get the DOM Node if you pass in a string
      el = typeof el === "string" ? document.querySelector(el) : el;

      var styles = window.getComputedStyle(el);
      var margin =
        parseFloat(styles["marginTop"]) + parseFloat(styles["marginBottom"]);

      return Math.ceil(el.offsetHeight + margin);
    },
    resetViewFilters() {
      this.tab_filters = {};
    },
    myInitViz() {
      var that = this;
      that.setContainerDimensions();

      if (
        typeof window.tableau != "undefined" &&
        typeof window.tableau.Viz != "undefined" &&
        typeof that.$refs.tableau != "undefined"
      ) {
        that.$refs.tableau.initViz();
      } else {
        console.log("tableau undefined");
        setTimeout(function () {
          that.myInitViz();
        }, 300);
      }
    },
    // Methods to trigger the export dialogs in the child component
    triggerExportPDF() {
      if (this.$refs.tableau) {
        this.$refs.tableau.exportPDF();
      } else {
        console.warn("Tableau component is not ready yet.");
      }
    },
    triggerExportImage() {
      if (this.$refs.tableau) {
        this.$refs.tableau.exportImage();
      } else {
        console.warn("Tableau component is not ready yet.");
      }
    },
  },
  watch: {
    $route(to, from) {
      console.log(to, from);
      // TODO combine the breadcrumb with Link return;
      this.breadcrumb = this.$store.getters.getBreadcrumb(to.params.portalLink);
      this.linkItem = this.$store.getters.getLinkItemByUrl(
        to.params.portalLink
      );
      this.tab_url = decodeURIComponent(to.params.portalLink);
      if (to.name == "publishedView") {
        this.myInitViz();
      }
    },
  },
  mounted() {
    if (
      (this.$store.state.portal =
        "" || this.$store.state.portal.uuid != this.$route.params.portalId)
    ) {
      var that = this;
      this.$http
        .get(
          process.env.VUE_APP_MANAGER_SERVER_URL +
          "/spaces/portal_config/" +
          this.$route.params.portalId
        )
        .then((response) => {
          //this.info = response.data.bpi;
          console.log(response);
          that.$store.commit("setPortalConfig", response.data.config);
          that.$customCssContainer.innerHTML = that.$store.state.portal.customCSS;
          if (that.$route.name == "publishedView") {
            var breadcrumb = that.$store.getters.getBreadcrumb(
              that.$route.params.portalLink
            );
            if (!breadcrumb) {
              // now revert back to landing page as no matching deep link
              console.log("deeplink not matched, going to landing page");
              that.$router.push({
                name: "publishedLanding",
                params: { portalId: that.$route.params.portalId },
              });
            } else {
              // Now embed the view
              //that.embedTableau();
              that.tab_url = decodeURIComponent(this.$route.params.portalLink);
              that.myInitViz();
              that.breadcrumb = breadcrumb;
              that.linkItem = that.$store.getters.getLinkItemByUrl(
                this.$route.params.portalLink
              );
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    } else {
      this.loading = false;
    }
  },
};
</script>