<template>
  <b-row class="row-main" no-gutters>
    <sidebar v-on:choose-template="onShowTemplatePicker"></sidebar>
    <b-col class="preview-pane">
      <preview-pane ref="previewPane"></preview-pane>
    </b-col>
    <b-overlay
      ref="myOverlay"
      :show="showTemplatePicker"
      no-wrap
      no-center
    >
      <template #overlay>
        <b-container fluid>
          <b-button @click="onHideTemplatePicker"><b-icon-x-circle></b-icon-x-circle> Close</b-button>
          <b-row
            class="mt-3 ml-3 mr-3"
            deck
            v-for="(i, index) in rowCount"
            :key="index"
          >
            <b-col
              cols="4"
              v-for="(template, idx2) in templateCountInRow(i)"
              :key="idx2"
            >
                <b-card
                @click="chooseTemplate(template)"
                  bg-variant="primary"
                  text-variant="white"
                  header="Primary"
                  class="text-center"
                >
                  {{ template.name }}
                </b-card>
            </b-col>
          </b-row>
        </b-container>
      </template>
    </b-overlay>
  </b-row>
</template>

<script>
import Sidebar from "../components/Sidebar.vue";
import PreviewPane from "../components/PreviewPane.vue";

export default {
  name: "EditView",
  components: {
    Sidebar,
    PreviewPane,
  },
  data() {
    return {
      busy: false,
      processing: false,
      counter: 1,
      interval: null,
      templatesPerRow: 3,
      showTemplatePicker:false
    };
  },
  computed: {
    templates() {
      return this.$store.state.templates;
    },
    rowCount: function () {
      return Math.ceil(this.templates.length / this.templatesPerRow);
    },
  },
  beforeDestroy() {
    this.clearInterval();
  },
  methods: {
    chooseTemplate: function(template){
      console.log('Applying Template', template);
      this.$store.commit('applyTemplateConfigs', template.template_config);
      // Now hide the overlay
      this.showTemplatePicker = false;
    },
    templateCountInRow: function (index) {
      return this.templates.slice(
        (index - 1) * this.templatesPerRow,
        index * this.templatesPerRow
      );
    },
    myTestListener() {
      console.log("caught event in parent");
    },
    clearInterval() {
      if (this.interval) {
        clearInterval(this.interval);
        this.interval = null;
      }
    },
    onShowTemplatePicker() {
      this.processing = false;
      this.showTemplatePicker = true;
    },
    onHideTemplatePicker() {
      this.showTemplatePicker = false;
    },
  },
};
</script>
    