<template>
  <draggable
    class="item-container"
    v-bind="dragOptions"
    tag="ul"
    :list="list"
    :value="value"
    @end="myEnd"
    @start="myStart"
    :clone="myClone"
  >
    <li :key="el.url" v-for="el in realValue" :class="{ view: isView(el) }">
      <template v-if="el.type == 'project'">
        <b-icon-folder></b-icon-folder>
      </template>
      <template v-if="el.type == 'workbook'">
        <b-icon-grid1x2></b-icon-grid1x2>
      </template>
      <template v-if="el.type == 'view'">
        <b-icon-file-richtext></b-icon-file-richtext>
      </template>
      <div class="item" :class="{ bold: !isView(el) }" @click="toggle(el)">
        {{ el.name }}
        <span v-if="!isView(el)">[{{ isOpen(el) ? "-" : "+" }}]</span>
        <b-icon-arrows-move v-if="el.type == 'view'" class="drag-handle"></b-icon-arrows-move>
      </div>
      <tableau-site-assets-tree-item v-show="isOpen(el)" class="item-sub" :list="el.children" />
    </li>
  </draggable>
</template>
<script>
import draggable from "vuedraggable";
import Vue from "vue";
//let idGlobal = 8;

export default {
  name: "TableauSiteAssetsTreeItem",
  components: { draggable },
  props: {
    value: {
      required: false,
      type: Array,
      default: null
    },
    list: {
      required: false,
      type: Array,
      default: null
    }
  },
  data: function() {
    return {};
  },
  computed: {
    dragOptions() {
      return {
        animation: 150,
        group: {
          name: "link-items",
          pull: "clone",
          put: false,
          revertClone: true
        },
        disabled: false,
        ghostClass: "ghost",
        draggable: ".view",
        sort: false
      };
    },
    // this.value when input = v-model
    // this.list  when input != v-model
    realValue() {
      return this.value ? this.value : this.list;
    }
  },
  methods: {
    myMove: function(evt, origEvt) {
      console.log("myMove2", evt, origEvt);
    },
    myChange: function(evt) {
      console.log("Tree Change", evt);
    },
    myStart: function(evt) {
      console.log("myStart", evt);
    },
    myEnd: function(evt) {
      console.log("myEnd", evt);
    },
    myClone: function(orig) {
      console.log("myClone", orig);
      //return orig;
      return {
        id: "myId",
        title: orig.name,
        url: orig.url,
        active: true,
        order: 0,
        tableau_link:orig.tableau_link
      };
    },
    isView: function(el) {
      return ["project", "site", "workbook"].indexOf(el.type) == -1;
    },
    isOpen: function(el) {
      if (typeof el.open != "undefined") {
        return el.open;
      } else {
        return false;
      }
    },
    toggle: function(el) {
      if (!this.isView(el)) {
        console.log(el.open);
        if (typeof el.open != "undefined") {
          Vue.set(el, "open", !el.open);
        } else {
          Vue.set(el, "open", true);
        }
      }
    }
  }
};
</script>
<style lang="scss">
.bold {
  font-weight: bold;
}

.drag-handle {
  cursor: move;
  cursor: -webkit-grabbing;
}

ul.item-container {
  padding-left: 0px;
  .item-sub {
    padding-left: 10px;
  }
}
.item {
  cursor: pointer;
}

.tableau-asset-tree .item{
  display:inline;
}
</style>