<template>
  <div>
    <form>
      <template v-if="!isLoggedIn">
        <div class="form-group">
          <label for="tableauServerUrl">Tableau Server URL</label>
          <input type="text" autocomplete="url" class="form-control form-control-sm" id="tableauServerUrl"
            v-model.trim.lazy="tableau_server_url" placeholder="Tableau Server URL" />
        </div>
        <div class="form-group">
          <label for="tableauSite">Tableau Server Site</label>
          <input type="text" class="form-control form-control-sm" id="tableauSite"
            v-model.trim.lazy="tableau_server_selected_site" placeholder="Tableau Server Site" />
        </div>
        <fieldset class="form-group">
          <legend class="col-form-label">Authentication Type</legend>
          <div class="form-group">
            <div class="row">
              <div class="form-check form-check-inline col">
                <b-form-radio class="form-check-input" type="radio" name="tableauServerLoginMethodOptions"
                  id="tableauServerLoginMethod1" v-model="tableau_server_login_method" value="username" />
                <label class="form-check-label" for="tableauServerLoginMethod1">Username / Password</label>
              </div>
              <div class="form-check form-check-inline col">
                <b-form-radio class="form-check-input" type="radio" name="tableauServerLoginMethodOptions"
                  id="tableauServerLoginMethod2" v-model="tableau_server_login_method" value="pat" />
                <label class="form-check-label" for="tableauServerLoginMethod2">Personal Access Token (PAT)</label>
              </div>
            </div>
          </div>
          <div v-if="tableau_server_login_method == 'username'">
            <div class="form-group">
              <label for="tableauUsername">Tableau user username</label>
              <input type="text" autocomplete="username" class="form-control form-control-sm" id="tableauUsername"
                v-model.trim.lazy="tableau_username" placeholder="Tableau Username" />
            </div>
            <div class="form-group">
              <label for="tableauPassword">Tableau user password</label>
              <input type="password" autocomplete="current-password" class="form-control form-control-sm"
                id="tableauPassword" v-model.trim.lazy="tableau_password" placeholder="Tableau Password" />
            </div>
          </div>
          <div v-if="tableau_server_login_method == 'pat'">
            <div class="form-group">
              <label for="tableauPatName">PAT Name</label>
              <input type="text" autocomplete="spaces_tableau_pat_name" class="form-control form-control-sm"
                id="tableauPatName" v-model.trim.lazy="tableau_server_pat_name" placeholder="PAT Name" />
            </div>
            <div class="form-group">
              <label for="tableauPatSecret">PAT Secret</label>
              <input type="password" autocomplete="spaces_tableau_pat_secret" class="form-control form-control-sm"
                id="tableauPatSecret" v-model.trim.lazy="tableau_server_pat_secret" placeholder="PAT Secret" />
            </div>
          </div>
        </fieldset>
        <div class="form-group">
          <label for="selectedProxy">Login via Proxy</label>
          <b-form-select v-model="selectedProxy">
            <b-form-select-option value>(none)</b-form-select-option>
            <b-form-select-option v-for="proxy in proxy_list" v-bind:value="proxy.id" v-bind:key="proxy.id">{{
              proxy.name
              }}</b-form-select-option>
          </b-form-select>
        </div>
        <b-button variant="secondary" v-on:click.stop.prevent="loginTableau" v-bind:disabled="loading">
          <template v-if="loading">
            <b-spinner small type="grow"></b-spinner>Loading...
          </template>
          <template v-else>Login</template>
        </b-button>
        {{ login_error_message }}
      </template>
      <template v-else>
        Logged in to Tableau as user:
        <span class="username">{{ userName }}</span>
        <b-link href="#" v-on:click.stop.prevent="logoutTableau">(Log out)</b-link>
        <li v-if="this.tableauTreeLoading">Loading Tableau Assets Tree...</li>
        <tableau-site-assets-tree-item class="tableau-asset-tree" v-model="siteData"></tableau-site-assets-tree-item>
      </template>
    </form>
  </div>
</template>
<script>
import qs from "qs";
import TableauSiteAssetsTreeItem from "./TableauSiteAssetsTreeItem.vue";

export default {
  name: "TableauServerLoginForm",
  components: {
    TableauSiteAssetsTreeItem
  },
  data: () => {
    return {
      tableau_username: "",
      tableau_password: "",
      tableau_server_url: "",
      tableau_server_selected_site: "",
      tableau_server_pat_name: "",
      tableau_server_pat_secret: "",
      tableau_server_site_list: [],
      login_error_message: "",
      loading: false,
      selectedProxy: "",
      tableauTreeLoading: false,
      tableau_server_login_method: 'username'
    };
  },
  computed: {
    siteData: {
      get() {
        return this.$store.state.tableau.siteData;
      },
      set(value) {
        console.log("setting value", value);
      }
    },
    proxy_list() {
      return this.$store.state.manager.proxy_list;
    },
    isLoggedIn() {
      return this.$store.state.tableau.isLoggedIn;
    },
    userName() {
      return this.$store.state.tableau.userName;
    },
    loginToken() {
      return this.$store.state.tableau.loginToken;
    }
  },
  methods: {
    logoutTableau: function () {
      this.login_error_message = "";
      this.$store.commit("updateTableauLoginState", false);
      this.$store.commit("updateTableauSiteData", false);
    },
    loginTableau: function () {
      console.log("logging in");
      this.loading = true;
      var that = this;
      const data = {
        host: this.tableau_server_url,
        site: this.tableau_server_selected_site,
        username: this.tableau_username,
        password: this.tableau_password,
        login_method: this.tableau_server_login_method,
        pat_name: this.tableau_server_pat_name,
        pat_secret: this.tableau_server_pat_secret
      };
      if (this.selectedProxy != "") {
        data.proxy = this.selectedProxy;
      }
      const options = {
        method: "POST",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        data: qs.stringify(data),
        url:
          process.env.VUE_APP_MANAGER_SERVER_URL + "/user/tableau/login_tableau",
        withCredentials: true
      };
      this.$http(options)
        .then(function (response) {
          console.log(response);
          if (response.data.success) {
            let payload = {
              data: response.data,
              user_name: that.tableau_username
            };
            that.$store.commit("updateTableauLoginState", payload);
            that.getSiteTree();
          } else {
            that.login_error_message = response.data.error;
          }
          that.loading = false;
        })
        .catch(function (error) {
          console.log(error);
          that.loading = false;
        });
    },
    treeSort: function (a, b) {
      var nameA = a.name.toUpperCase(); // ignore upper and lowercase
      var nameB = b.name.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    },
    getChildren: function (type, parent_id, projects, workbooks, views) {
      var that = this;
      if (type == "project") {
        var children = [];
        //get child projects
        var filteredProjects = projects.filter(project => {
          return project.parentProjectId == parent_id;
        });
        // transform the output
        filteredProjects.forEach((project, idx) => {
          children.push({
            type: "project",
            name: project.name,
            index: idx,
            children: that.getChildren(
              "project",
              project.id,
              projects,
              workbooks,
              views
            )
          });
        });
        //get child workbooks
        var filteredWorkbooks = workbooks.filter(workbook => {
          return workbook.project_id == parent_id;
        });
        filteredWorkbooks.forEach((workbook, idx) => {
          children.push({
            type: "workbook",
            name: workbook.name,
            index: idx,
            children: that.getChildren(
              "workbook",
              workbook.id,
              projects,
              workbooks,
              views
            )
          });
        });
        // Sort Children
        children.sort(that.treeSort);
        return children;
      } else if (type == "workbook") {
        // Get all views with the parent_id
        var workbookViews = [];
        var filteredViews = views.filter(view => {
          return view.workbook_id == parent_id;
        });
        filteredViews.forEach(function (filteredView, idx) {
          // Remove trailing slashes from the server URL once
          const serverUrl = that.tableau_server_url.replace(/\/+$/, '');
          workbookViews.push({
            name: filteredView.name,
            url: `${serverUrl}/t/${that.tableau_server_selected_site}/views/${filteredView.contentUrl.replace('/sheets', '')}`,
            type: "view",
            index: idx,
            tableau_link: true
          });
        });
        workbookViews.sort(that.treeSort);
        return workbookViews;
      }
    },
    getSiteTree: function () {
      this.tableauTreeLoading = true;
      console.log("getting site tree");
      this.loading = true;
      var that = this;
      let optionsProjects = {
        method: "POST",
        headers: { "x-brilliant-auth": this.loginToken },
        //data: qs.stringify(data),
        url:
          process.env.VUE_APP_MANAGER_SERVER_URL + "/user/tableau/get_all_projects",
        withCredentials: true
      };

      let optionsWorkbooks = {
        method: "POST",
        headers: { "x-brilliant-auth": this.loginToken },
        //data: qs.stringify(data),
        url:
          process.env.VUE_APP_MANAGER_SERVER_URL + "/user/tableau/get_all_workbooks",
        withCredentials: true
      };

      let optionsViews = {
        method: "POST",
        headers: { "x-brilliant-auth": this.loginToken },
        //data: qs.stringify(data),
        url:
          process.env.VUE_APP_MANAGER_SERVER_URL + "/user/tableau/get_all_views",
        withCredentials: true
      };

      var projectsPromise = this.$http(optionsProjects);
      var workbooksPromise = this.$http(optionsWorkbooks);
      var viewsPromise = this.$http(optionsViews);
      Promise.all([projectsPromise, workbooksPromise, viewsPromise]).then(
        function (values) {
          //console.log("Retrieved all promises");
          // Now build a tree
          // Entry point - get all projects with no project parent
          var projects = values[0].data.data;
          var workbooks = values[1].data.data;
          var views = values[2].data.data;
          var rootProjects = projects.filter(
            project => project.parentProjectId == ""
          );

          var rootChildren = [];
          rootProjects.forEach((rootProject, idx) => {
            var project = {
              type: "project",
              name: rootProject.name,
              index: idx,
              children: that.getChildren(
                "project",
                rootProject.id,
                projects,
                workbooks,
                views
              )
            };
            rootChildren.push(project);
          });
          rootChildren.sort(that.treeSort);
          // Add these to the parent site and set in the store.
          let payload = {
            data: {
              name: that.tableau_server_selected_site,
              type: "site",
              children: rootChildren
            }
          };
          console.log(payload);
          that.$store.commit("updateTableauSiteData", payload);
          that.tableauTreeLoading = false;
        }
      );
    },
    getProjects: function () {
      console.log("getting projects");
      this.loading = true;
      var that = this;
      let options = {
        method: "POST",
        headers: { "x-brilliant-auth": this.loginToken },
        //data: qs.stringify(data),
        url:
          process.env.VUE_APP_MANAGER_SERVER_URL + "/user/tableau/get_all_projects",
        withCredentials: true
      };
      this.$http(options)
        .then(function (response) {
          console.log(response);
          if (!response.data.error) {
            console.log(response);
            let payload = {
              data: {
                name: that.tableau_server_selected_site,
                type: "site",
                children: []
              }
            };
            response.data.data.forEach(function (item, idx) {
              payload.data.children.push({
                type: "project",
                name: item.name,
                index: idx,
                children: [
                  {
                    type: "workbook",
                    name: "Workbook 1",
                    children: [
                      {
                        type: "view",
                        name: "View 1",
                        id: 100,
                        url: "http://tableauview.com"
                      },
                      {
                        type: "view",
                        name: "View 2",
                        id: 200,
                        url: "http://tableauview.com"
                      }
                    ]
                  }
                ]
              });
            });
            that.$store.commit("updateTableauSiteData", payload);
          } else {
            that.login_error_message = response.data.error;
          }
          that.loading = false;
        })
        .catch(function (error) {
          console.log(error);
          that.loading = false;
        });
    }
  }
};
</script>
<style scoped>
fieldset {
  border: 1px solid grey;
  padding: 0 10px;
}

fieldset legend {
  width: inherit;
  border-bottom: none;
}

fieldset .form-check-inline {
  padding-left: 1.25rem;
}
</style>
