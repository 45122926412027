<template>
  <b-card>
    <form>
      <template v-if="!intially_loaded">Checking Login Status....</template>
      <template v-else-if="login_status_error">{{ login_status_error }}</template>
      <template v-else-if="!isLoggedIn">
        <template v-if="totp_required">
          <div class="form-group">
            <label for="totpCode">Enter TOTP Code</label>
            <input type="text" class="form-control form-control-sm" id="totpCode" v-model.trim.lazy="totp_code"
              placeholder="Enter TOTP Code" v-on:keyup.enter="verifyTotp" />
          </div>
          <b-button variant="secondary" v-on:click.stop.prevent="verifyTotp" v-bind:disabled="loading">
            <template v-if="loading">
              <b-spinner small type="grow"></b-spinner> Verifying...
            </template>
            <template v-else>Verify</template>
          </b-button>
          {{ totp_error_message }}
        </template>
        <template v-else>
          <div class="form-group">
            <label for="managerUsername">Brilliant Manager Username</label>
            <input type="text" autocomplete="username" class="form-control form-control-sm" id="managerUsername"
              v-model.trim.lazy="manager_username" placeholder="Brilliant Manager Username" />
          </div>
          <div class="form-group">
            <label for="managerPassword">Brilliant Manager Password</label>
            <input type="password" autocomplete="current-password" class="form-control form-control-sm"
              id="managerPassword" v-model.trim.lazy="manager_password" placeholder="Brilliant Manager Password"
              v-on:keyup.enter="loginManager" />
          </div>
          <b-button variant="secondary" v-on:click.stop.prevent="loginManager" v-bind:disabled="loading">
            <template v-if="loading">
              <b-spinner small type="grow"></b-spinner> Loading...
            </template>
            <template v-else>Login</template>
          </b-button>
          {{ login_error_message }}
        </template>
      </template>
      <template v-else>
        Logged in to Manager as user:
        <span class="username">{{ userName }}</span>
        <b-link href="#" v-on:click.stop.prevent="logoutManager">(Log out)</b-link>
        <my-portals-list></my-portals-list>
      </template>
    </form>
  </b-card>
</template>

<script>
import qs from "qs";
import MyPortalsList from "../components/MyPortalsList.vue";

export default {
  components: {
    MyPortalsList,
  },
  name: "ManagerLoginStatus",
  data: () => {
    return {
      manager_username: "",
      manager_password: "",
      totp_code: "",
      login_error_message: "",
      totp_error_message: "",
      loading: false,
      intially_loaded: false,
      login_status_error: false,
      totp_required: false,
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.manager.isLoggedIn;
    },
    userName() {
      return this.$store.state.manager.userName;
    },
  },
  mounted() {
    this.checkManagerLoginStatus();
  },
  methods: {
    checkManagerLoginStatus() {
      console.log("Checking Login Status");
      this.loading = true;
      const options = {
        method: "GET",
        url: process.env.VUE_APP_MANAGER_SERVER_URL + "/user/spaces/login_status",
        withCredentials: true,
      };
      this.$http(options)
        .then((response) => {
          console.log(response);
          this.intially_loaded = true;
          this.$store.commit("updateManagerLoginState", response.data);
          this.loading = false;
        })
        .catch((error) => {
          console.error(error);
          this.loading = false;
          this.intially_loaded = true;
          this.login_status_error =
            "Error fetching sign-in status. Please try reloading or contact the admin if the issue persists";
        });
    },
    logoutManager() {
      console.log("logging out");
      this.loading = true;
      const options = {
        method: "GET",
        url: process.env.VUE_APP_MANAGER_SERVER_URL + "/account/logout_ajax",
        withCredentials: true,
      };
      this.$http(options)
        .then(() => {
          this.$store.commit("updateManagerLoginState", false);
          this.loading = false;
        })
        .catch((error) => {
          console.error(error);
          this.loading = false;
        });
    },
    loginManager() {
      console.log("logging in");
      this.loading = true;
      const data = {
        login_string: this.manager_username,
        login_pass: this.manager_password,
      };
      const options = {
        method: "POST",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        data: qs.stringify(data),
        url: process.env.VUE_APP_MANAGER_SERVER_URL + "/account/signin",
        withCredentials: true,
      };
      this.$http(options)
        .then((response) => {
          console.log(response);
          if (response.data.success) {
            if (response.data.totp_required) {
              this.totp_required = true;
            } else {
              this.$store.commit("updateManagerLoginState", response.data);
            }
          } else {
            this.login_error_message = response.data.error;
          }
          this.loading = false;
        })
        .catch((error) => {
          console.error(error);
          this.loading = false;
        });
    },
    verifyTotp() {
      console.log("verifying TOTP");
      this.loading = true;
      const data = {
        totp_code: this.totp_code,
      };
      const options = {
        method: "POST",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        data: qs.stringify(data),
        url: process.env.VUE_APP_MANAGER_SERVER_URL + "/account/verify_totp_api",
        withCredentials: true,
      };
      this.$http(options)
        .then((response) => {
          console.log(response);
          if (response.data.success) {
            this.$store.commit("updateManagerLoginState", response.data);
          } else {
            this.totp_error_message = response.data.error;
          }
          this.loading = false;
        })
        .catch((error) => {
          console.error(error);
          this.loading = false;
        });
    },
  },
};
</script>

<style>
span.username {
  font-weight: bold;
  margin: 0 0.5em;
}

ul {
  list-style: none;
}
</style>
